import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const UserTemp = RepositoryFactory.get('userTemp')
import pickBy from 'lodash-es/pickBy'
import orderBy from 'lodash-es/orderBy'

const state = {
  contacts: [],
  orders: [],
  totalContacts: 0,
  totalOrders: 0,
  orderStringParameter: {},
  contactStringParameter: {},
}

const actions = {
  async fetchContacts({commit, rootState}, params = {}) {
    const queryParams = {
      _skip: 0,
      _limit: 10,
      ...params
    }
    const cleanParams = pickBy({...queryParams, ...rootState.userTemp.contactStringParameter}, (value) => value !== undefined && value !== null && value !== '')
    const res = await UserTemp.fetchContact(cleanParams)
    const sortData = orderBy(res.data || [], ['createdAt'], ['desc'])
    commit('setContacts', sortData)
    return res.data || []

  },
  async fetchOrders({commit,rootState}, params = {}) {
    // Xây dựng query params
    const queryParams = {
      _skip: 0,
      _limit: 10,
      ...params
    }
    const cleanParams = pickBy({...queryParams, ...rootState.userTemp.orderStringParameter, _sort: 'createdAt:desc',}, (value) => value !== undefined && value !== null && value !== '')
    const res = await UserTemp.fetchOrder(cleanParams)
    commit('setOrders', res.data || [])
    return res.data || []

  },
  async updateOrder({commit}, params = {}) {
    const res = await UserTemp.update(params)
    commit('addOrUpdateOrder', res.data || {})
  },
  async countContacts({commit, rootState}, params = {}) {
    const cleanParams = pickBy({...params, ...rootState.userTemp.contactStringParameter}, (value) => value !== undefined && value !== null && value !== '')
    const res = await UserTemp.countContact(cleanParams)
    commit('setTotalContacts', res.data || 0)
    return res.data || 0
  },
  async countOrders({commit, rootState}, params = {}) {
    const cleanParams = pickBy({...params, ...rootState.userTemp.orderStringParameter}, (value) => value !== undefined && value !== null && value !== '')
    const res = await UserTemp.countOrder(cleanParams)
    commit('setTotalOrders', res.data || 0)
    return res.data || 0
  },
  setOrderStringParameter({commit}, params = {}) {
    commit('setOrderStringParameter', params)
  },
  setContactStringParameter({commit}, params = {}) {
    commit('setContactStringParameter', params)
  }
}

const mutations = {
  setContacts(state, contacts) {
    state.contacts = contacts.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
  },
  setOrders(state, orders) {
    state.orders = orders.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
  },
  addOrUpdateOrder(state, item) {
    if (item && item.id) {
      const foundIndex = state.orders.findIndex((order) => order.id === item.id)
      if (foundIndex > -1) {
        state.orders.splice(foundIndex, 1, item)
      } else {
        state.orders.unshift(item)
      }
    }
  },
  setTotalContacts(state, total) {
    state.totalContacts = total
  },
  setTotalOrders(state, total) {
    state.totalOrders = total
  },
  setOrderStringParameter(state, value) {
    state.orderStringParameter = value
  },
  setContactStringParameter(state, value) {
    state.contactStringParameter = value
  }
  
}

const getters = {
  getContacts(state) {
    return state.contacts
  },
  getOrders(state) {
    return state.orders
  },
  getTotalContacts(state) {
    return state.totalContacts
  },
  getTotalOrders(state) {
    return state.totalOrders
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
