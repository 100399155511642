import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const DeliveryBill = RepositoryFactory.get('deliveryBill')
import vendor from '@/store/modules/vendor'

const state = {
  deliveryBills: [],
  deliveryBill: {},
  count: 0,
}

const actions = {
  async fetchDeliveryBills({commit}, params = {}) {
    const res = await DeliveryBill.fetch({
      ...params,
    })
    commit('setDeliveryBills', res.data || [])
    return res.data
  },
  async countDeliveryBills({commit}, params) {
    const res = await DeliveryBill.count(params)
    commit('setDeliveryBill', res.data)
    return res.data
  },
  async updateManyDeliveryBills({commit}, params) {
    const res = await DeliveryBill.updateMany({...params, vendorId: vendor.state.vendor.vendorId})
    commit('setDeliveryBills', res.data)
    return res.data
  },
  async updateDeliveryBill({commit, rootState}, {id, params}) {
    const res = await DeliveryBill.update(id, {...params, vendorId: vendor.state.vendor.vendorId})
    const newData = rootState.deliveryBill.deliveryBills.map((item) => (item.id === id ? res.data : item))
    commit('setDeliveryBills', newData)
    return res.data
  },
}

const mutations = {
  setDeliveryBills(state, value) {
    return (state.deliveryBills = value)
  },
  setDeliveryBill(state, value) {
    return (state.count = value)
  },
}

const getters = {
  getDeliveryBills: (state) => {
    return state.deliveryBills
  },
  getCount: (state) => {
    return state.count
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
