import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const Zoom = RepositoryFactory.get('zoom')
const namespaced = true

const state = {
	zooms: {},
	zoom: {},
	count: 0
}

const actions = {
	async createZoom({commit}, zoom) {
		const res = await Zoom.create(zoom)
		return commit('setZoom', res.data)
	},
	async countZoom({commit}, params) {
		const res = await Zoom.count(params)
		return commit('setCount', res.data)
	},
	async fetchZooms({commit}, params) {
		const res = await Zoom.fetch({...params, _sort: 'createdAt:asc',})
		return commit('setZooms', res.data || [])
	},
	async updateZoom({commit}, {id, ...zoom}) {
		const res = await Zoom.update(id, zoom)
		return commit('setZoom', res.data)
	},
	async removeZoom({commit}, item) {
		await Zoom.remove(item.id)
		return commit('removeZoom', item.id)
	},
	async setZoom({commit}, zoom) {
		return commit('setZoomData', zoom)
	}
}

const mutations = {
	setZoom(state, zoom) {
		state.zooms = {
			...state.zooms,
			[zoom.id]: zoom
		}
		state.count = Object.values(state.zooms).length
	},
	setZoomData(state, zoom) {
		return (state.zoom = zoom)
	},
	setCount(state, count) {
		return (state.count = count)
	},
	setZooms(state, zooms) {
		state.zooms = zooms.reduce((acc, cur) => ({...acc, [cur.id]: cur}), {})
	},
	removeZoom(state, id) {
		delete state.zooms[id]
		state.zooms = {...state.zooms}
	}
}

const getters = {
	zooms: state => {
		return Object.values(state.zooms).reverse()
	},
	zoom: state => {
		return state.zoom
	},
	count: state => {
		return state.count
	}
}

export default {
	namespaced,
	state,
	actions,
	mutations,
	getters
}
