//MANAGE ACTIVATION CODE
const ACTIVATION_CODE_CAMPAIGN_LIST = {
	TITLE: 'Mã kích hoạt',
	BUTTON_ADD: 'Thêm chương trình',
	LABEL_FILTER_BY_NAME: 'Tên chương trình',
	PLACEHOLDER_FILTER_BY_NAME: 'Tìm kiếm theo tên chương trình',
	BUTTON_IMPORT_EXPORT_DATA_BY_EXCEL: 'Nhập/xuất Excel',
	BUTTON_IMPORT_DATA_BY_EXCEL_FILE: 'Nhập file Excel',
	BUTTON_DOWNLOAD_EXCEL_TEMPLATE: 'Download file Excel',
	BUTTON_EXPORT_DATA_TO_EXCEL_FILE: 'Xuất file Excel',
	// TABLE
	TH_NAME: 'Tên chương trình',
	TH_COURSE_NAME: 'Tên khoá học',
	TH_EXPIRED_AT: 'Hạn sử dụng',
	TH_CODE_QUANTITY: 'Mã đã dùng',
	TH_CODE_QUANTITY_DIVIDE_TOTAL: 'Mã đã dùng / Tổng',
	TH_AVAILABLE_DATE: 'Ngày bắt đầu',
	TH_ACTION: 'Thao tác',
	TOOLTIP_EDIT_ACTIVE_CODE: 'Chỉnh sửa mã kích hoạt',
	// EXPORTING DATA
	EXPORTING_DATA_TH_CODE: 'Mã kích hoạt',
	// IMPORTING DATA
	MSG_IMPORT_FAIL_READING_FILE_FAIL: 'Có lỗi khi đọc file, vui lòng thử lại!',
	MSG_IMPORT_FAIL_EMPTY_FILE: 'Vui lòng upload file có nội dung',
	MSG_IMPORT_FAIL_INCORRECT_ROWS: 'Những dòng sai trong file: {0}',
	MSG_IMPORT_SUCCESS: 'Thêm học viên thành công',
	// EMPTY DATA
	TITLE_EMPTY_DATA: 'Chưa tồn tại chương trình cho mã kích hoạt',
	SUBTITLE_EMPTY_DATA: 'Vui lòng tạo mới để hiển thị trên danh sách này'
}
const FORM_CREATE_NEW_ACTIVATION_CODE_CAMPAIGN = {
	TITLE: 'Tạo mới',
	LINK_RETURN_TO_CAMPAGIN_LIST: 'Danh sách Chương trình',
	BUTTON_ADD: 'Thêm chương trình',
	MSG_CREATING_CODE: 'Đang tạo chương trình...',
	MSG_CREATE_CODE_SUCCESS: 'Tạo chương trình thành công',
	// BASIC_INFO
	TITLE_CAMPAIGN_INFOS: 'Thông tin chương trình',
	LABE_CAMPAIGN_NAME: 'Tên chương trình',
	PLACEHOLDER_CAMPAGIN_NAME: 'Nhập tên chương trình',
	LABEL_COURSE_TYPE: 'Loại khoá học',
	TEXT_COURSE_TYPE_ONLINE: 'Online',
	LABEL_COURSE: 'Khoá học được áp dụng',
	PLACEHOLDER_COURSE: 'Chọn khoá học',
	MSG_CHECK_INFO_AGAIN: 'Vui lòng kiểm tra lại thông tin',
	// ADVANCED_INFO
	TITLE_CODE_INFOS: 'Thông tin mã kích hoạt',
	CHECKBOX_REUSABLE_CODE: 'Mã sử dụng nhiều lần',
	LABEL_CODE_QUANTITY: 'Số mã cần tạo',
	PLACEHOLDER_CODE_QUANTITY: 'Nhập số lượng mã muốn tạo',
	LABEL_AVAILABLE_DATE: 'Thời gian bắt đầu (0h 00)',
	PLACEHOLDER_AVAILABLE_DATE: 'Chọn thời gian bắt đầu',
	LABEL_EXPIRATION_DATE: 'Thời gian kết thúc (0h 00)',
	PLACEHOLDER_EXPIRATION_DATE: 'Chọn thời gian kết thúc',
	MSG_CHECK_TIME_INFO_AGAIN: 'Vui lòng kiểm tra lại thời gian!'
}
const CAMPAIGN_ACTIVATION_CODE_DETAIL = {
	LINK_RETURN_TO_CAMPAGIN_LIST: 'Danh sách Chương trình',
	// TAB
	TAB_CAMPAIGN_INFO: 'Thông tin chương trình',
	TAB_CODE_LIST: 'Danh sách mã',
	// BASIC_INFO
	TITLE_CAMPAIGN_INFOS: 'Thông tin chương trình',
	LABEL_COURSE: 'Khoá được kích hoạt',
	LABEL_COURSE_TYPE: 'Loại khoá học',
	TEXT_COURSE_TYPE_ONLINE: 'online',
	LABEL_REUSABLE_CODE: 'Mã dùng nhiều lần',
	TEXT_STATUS_ACTIVE: 'Kích hoạt',
	TEXT_STATUS_INACTIVE: 'Không kích hoạt',
	LABEL_AVAILABLE_AT: 'Có hiệu lực từ',
	LABEL_EXPIRED_AT: 'Hạn sử dụng',
	LABEL_CODE_QUANTITY: 'Tổng số mã',
	LABEL_USED_CODE_QUANTITY: 'Mã đã dùng',
	// TRANSACTIONS
	TITLE_TRANSACTIONS: 'Lịch sử giao dịch',
	TH_CODE: 'Mã',
	TH_USED_AT: 'Ngày kích hoạt',
	TH_STUDENT_NAME: 'Tên học viên',
	TH_STUDENT_EMAIL: 'Email',
	TH_STUDENT_PHONE: 'Số điện thoại',
	// AVAILABLE CODES
	TITLE_AVAILABLE_CODES: 'Mã chưa kích hoạt',
	TH_AVAILABLE_CODE: 'Mã',
	TH_ACTION: 'Thao tác',
	// MESSAGE
	MSG_COPY_SUCCESS: 'Sao chép thành công!',
	MSG_COPY_FAIL: 'Sao chép không thành công. Vui lòng thử lại!'
}
//MANAGE COUPON
const COUPON_CAMPAIGN_LIST = {
	TITLE: 'Coupon',
	BUTTON_ADD: 'Thêm chương trình',
	LABEL_NAME: 'Tên chương trình',
	PLACEHOLDER_NAME: 'Tìm theo tên chương trình',
	// TABLE
	TH_NAME: 'Tên chương trình',
	TH_COURSE_TYPE: 'Loại khóa học',
	TH_DISCOUNT_VALUE: 'Khuyến mãi',
	TH_AVAILABLE_AT: 'Bắt đầu',
	TH_EXPIRED_AT: 'Kết thúc',
	TH_USED_CODE: 'Mã đã dùng',
	TH_ACTION: 'Thao tác',
	TOOLTIP_EDIT_COUPON: 'Chỉnh sửa Coupon',
	// EMPTY DATA
	TITLE_EMPTY_DATA: 'Chưa tồn tại chương trình cho mã giảm giá',
	SUBTITLE_EMPTY_DATA: 'Vui lòng tạo mới để hiển thị trên danh sách này'
}
const FORM_CREATE_NEW_COUPON_CAMPAIGN = {
	TITLE: 'Tạo mới',
	LINK_RETURN_TO_COUPON_LIST: 'Danh sách Chương trình',
	BUTTON_SAVE: 'Lưu Chương trình',
	// MASSAGE
	MSG_CREATING_CODE: 'Đang tạo chương trình...',
	MSG_CREATE_SUCCESS: 'Tạo chương trình thành công',
	// BASIC INFO
	TITLE_BASIC_INFOS: 'Thông tin cơ bản',
	LABEL_CAMPAIGN_NAME: 'Tên chương trình',
	PLACEHOLDER_CAMPAIGN_NAME: 'Nhập tên chương trình',
	LABEL_COURSE_TYPE: 'Loại khóa học',
	PLACEHOLDER_COURSE_TYPE: 'Chọn loại khóa học',
	TEXT_COURSE_TYPE_ONLINE: 'Online',
	TEXT_COURSE_TYPE_OFFLINE: 'Offline',
	LABEL_COURSE: 'Khóa học được áp dụng',
	PLACEHOLDER_COURSE: 'Chọn khoá học',
	MSG_CHECK_BASIC_INFO_AGAIN: 'Kiểm tra lại thông tin cơ bản!',
	// DISCOUNT INFO
	TITLE_DISCOUNT_INFOS: 'Thông tin khuyến mãi',
	LABEL_COURSE_PROCE: 'Giá gốc',
	LABEL_DISCOUNT_VALUE: 'Giá trị khuyến mãi',
	PLACEHOLDER_DISCCOUNT_VALUE: 'Nhập giá trị khuyến mãi',
	LABEL_CODE_QUANTITY: 'Tổng mã',
	PLACEHOLDER_CODE_QUANTITY: 'Nhập tổng số mã muốn tạo',
	LABEL_AVAILABLE_DATE: 'Thời gian bắt đầu',
	PLACEHOLDER_AVAILABLE_DATE: 'Chọn thời gian bắt đầu',
	LABEL_EXPIRATION_DATE: 'Thời gian kết thúc',
	PLACEHOLDER_EXPIRATION_DATE: 'Chọn thời gian kết thúc',
	MSG_CHECK_DISCOUNT_INFO_AGAIN: 'Vui lòng kiểm tra lại thông tin khuyến mãi!'
}
const COUPON_CAMPAIGN_DETAIL = {
	LINK_RETURN_TO_CAMPAGIN_LIST: 'Danh sách Chương trình',
	// TAB
	TAB_CAMPPAIGN_INFO: 'Thông tin chương trình',
	TAB_CODE_LIST: 'Danh sách mã',
	// BASIC INFO
	TITLE_CAMPAIGN_INFOS: 'Thông tin chương trình',
	LABEL_CAMPAIGN_NAME: 'Tên chương trình',
	LABEL_COURSE_TYPE: 'Loại khóa học',
	PLACEHOLDER_COURSE_TYPE: 'Chọn loại khóa học',
	TEXT_COURSE_TYPE_ONLINE: 'Online',
	TEXT_COURSE_TYPE_OFFLINE: 'Offline',
	LABEL_COURSE: 'Khóa học được áp dụng',
	PLACEHOLDER_COURSE: 'Chọn khoá học',
	TEXT_COURSE_NOT_SET_YET: 'Chưa lựa chọn khóa học',
	MSG_DATA_LOADING: 'Đang lấy dữ liệu ...',
	MSG_CHECK_BASIC_INFO_AGAIN: 'Kiểm tra lại thông tin cơ bản!',
	MSG_PROMOTION_INFO_AGAIN: 'Vui lòng kiểm tra lại thông tin khuyến mãi!',
	// DISCOUNT INFO
	TITLE_DISCOUNT_INFOS: 'Nội dung khuyến mãi',
	LABEL_COURSE_PRICE: 'Giá gốc',
	LABEL_DISCOUNT_VALUE: 'Giá trị khuyến mãi',
	TEXT_CURRENCY_UNIT: 'đ',
	LABEL_CODE_QUANTITY: 'Tổng số mã',
	LABEL_USED_CODE_QUANTITY: 'Mã đã dùng',
	LABEL_AVAILABLE_AT: 'Thời gian bắt đầu',
	LABEL_EXPIRATION_AT: 'Thời gian kết thúc',
	LABEL_TIME: 'Thời gian',
	MSG_CHECK_INFO_AGAIN: 'Vui lòng kiểm tra lại thông tin khuyến mãi!',
	// USED CODES
	LABEL_USED_CODE: 'Mã đã dùng',
	LABEL_CODE: 'Mã giảm giá',
	PLACEHOLDER_CODE: 'Tìm kiếm theo mã',
	BUTTON_VIEW_MORE: 'Xem thêm',
	TH_CODE: 'Mã',
	TH_ACTION: 'Thao tác',
	TH_USED_AT: 'Ngày dùng',
	TH_STUDENT_ACCOUNT: 'Tài khoản dùng',
	TH_BILL_TOTAL_VALUE: 'Giá trị đơn hàng (đ)',
	TH_DISCOUNT_VALUE: 'Khuyến mãi ',
	TH_CODE_STATUS: 'Tình trạng',
	TH_VIEW_BILL: 'Đơn hàng',
	TEXT_CODE_STATUS_USED: 'Đã dùng',
	TEXT_CODE_STATUS_NOT_USED_YET: 'Chưa sử dụng',
	MSG_COPY_CODE_SUCCESS: 'Sao chép thành công!',
	MSG_COPY_CODE_FAIL: 'Sao chép không thành công. Vui lòng thử lại',
	// BUTTON
	BUTTON_CANCEL: 'Huỷ',
	// MASSAGE
	MSG_UPDATING: 'Đang cập nhật thông tin...',
	MSG_UPDATE_SUCCESS: 'Cập nhật thành công',
	// DELETE
	TITLE_DELETE_ACTION: 'Xoá chương trình',
	SUBTITLE_DELETE_ACTION:
		'Sau khi xoá chương trình, tất cả các mã chưa được dùng sẽ tự động bị vô hiệu hoá.',
	BUTTON_DELETE: 'Xoá chương trình',
	POPUP_DELETION_CONFIRM_TITLE: 'Xoá chương trình',
	POPUP_DELETION_COMFIRM_MESSAGE:
		'Dữ liệu sau khi xóa không thể khôi phục. Bạn có chắc chắn muốn xóa chương trình giảm giá này này ?',
	MSG_DELETE_SUCCESS: 'Xoá thành công!'
}
//MANAGE COMBO
const COMBO_CAMPAIGN_LIST = {
	TITLE: 'Combo',
	BUTTON_ADD: 'Thêm chương trình',
	LABEL_FILTER_BY_NAME: 'Tên chương trình',
	PLACEHOLDER_FILTER_BY_NAME: 'Tìm kiếm theo tên chương trình',
	// TABLE
	TH_NAME: 'Tên chương trình',
	TH_DISCOUNT_VALUE: 'Giá trị',
	TH_AVAILABLE_AT: 'Bắt đầu',
	TH_EXPIRED_AT: 'Hết hạn',
	TH_ACTION: 'Thao tác',
	// DELETE ACTION
	POPUP_CONFIRM_DELETION_TITLE: 'Xoá Combo',
	POPUP_CONFIRM_DELETION_MESSAGE:
		'Dữ liệu sau khi xóa không thể khôi phục. Bạn có chắc chắn muốn xóa combo này ?',
	// MESSAGE
	MSG_DELETING: 'Đang xoá...',
	MSG_DELETE_SUCCESS: 'Xoá combo thành công'
}
const COMBO_CAMPAIGN_DETAIL = {
	LINK_RETURN_TO_COMBO_LIST: 'Danh sách chương trình',
	// BASIC INFO
	TITLE_CAMPAIGN_INFOS: 'Thông tin cơ bản',
	LABEL_NAME: 'Tên combo',
	LABEL_AVAILABLE_TIME: 'Thời gian bắt đầu',
	LABEL_EXPIRED_TIME: 'Thời gian kết thúc',
	// BASIC INFO EDIT FORM
	PLACEHOLDER_IMPORT_NAME: 'Nhập tên combo',
	PLACEHOLDER_AVAILABLE_TIME: 'Chọn thời gian bắt đầu',
	PLACEHOLDER_EXPIRED_TIME: 'Chọn thời gian kết thúc',
	MSG_UPDATE_SUCCESS: 'Cập nhật combo thành công',
	// CONDITION APPLY COMBO
	TITLE_CONDITION: 'Điều kiện áp dụng',
	SUBTITLE_CONDITION:
		'Khi giỏ hàng của học viên đạt đủ điều kiện, Combo giảm giá này sẽ được áp dụng. Điều kiện áp dụng chỉ có thể cài đặt khi đang tạo combo, không được chỉnh sửa sau khi đã lưu.',
	LABEL_MIN_NUMBER_OF_COURSES: 'Khoá học tối thiểu',
	LABEL_PRICE_APPLY: 'Số tiền áp dụng',
	LABEL_COURSE_APPLY: 'Khoá học áp dụng',
	BUTTON_ADD_CONDITION: 'Thêm điều kiện',
	//VALUE COMBO
	TITLE_VALUE: 'Giá trị combo',
	SUBTITLE_VALUE: 'Bạn không thể thay đổi giá trị combo sau khi khởi tạo.',
	LABEL_CURRENCY_UNIT: 'VND',
	//DELETE COMBO
	TITLE_DELETION: 'Xoá Combo',
	SUBTITLE_DELETION:
		'Học viên vẫn có thể truy cập vào khóa học đã mua qua gói combo. Thông tin combo trên Quản lý đơn hàng và Doanh số vẫn sẽ được lưu lại.',
	BUTTON_DELETE: 'Xoá combo',
	POPUP_CONFIRM_DELETE_TITLE: 'Xoá Combo',
	POPUP_CONFIRM_DELETE_MESSAGE:
		'Dữ liệu sau khi xóa không thể khôi phục. Bạn có chắc chắn muốn xóa combo này ?',
	MSG_DELETING: 'Đang xoá...',
	MSG_DELETE_SUCCESS: 'Xoá combo thành công',
	MSG_UPDATING: 'Đang cập nhật...',

	COMBO_PUBLISHER: {
		TITLE: "Xuất bản",
		SUBTITLE_ACTIVATION: 'Combo sẽ chỉ được áp dụng khi được kích hoạt.',
		SUBTITLE_HIDE: 'Combo này sẽ bị ẩn khỏi web',
		BUTTON_ACTIVE: 'Kích hoạt',
		BUTTON_BUTTON_ACTIVE: 'Tạm dừng',
	}
}
const FORM_CREATE_NEW_COMBO_CAMPAIGN = {
	TITLE: 'Tạo mới',
	LINK_RETURN_TO_COMBO_LIST: 'Danh sách chương trình',
	//BASIC INFO
	TITLE_BASIC_INFO: 'Thông tin cơ bản',
	LABEL_NAME: 'Tên combo',
	PLACEHOLDER_INPUT_NAME: 'Nhập tên combo',
	LABEL_AVAILABLE_DATE: 'Thời gian bắt đầu',
	PLACEHOLDER_SELECT_AVAILABLE_DATE: 'Chọn thời gian bắt đầu',
	LABEL_EXPIRATION_DATE: 'Thời gian kết thúc',
	PLACEHOLDER_SELECT_EXPIRATION_DATE: 'Chọn thời gian kết thúc',
	MSG_UPDATE_SUCCESS: 'Cập nhật combo thành công',
	// CONDITION
	TITLE_CONDITION: 'Điều kiện áp dụng',
	SUBTITLE_CONDITION:
		'Khi giỏ hàng của học viên đạt đủ điều kiện, Combo giảm giá này sẽ được áp dụng. Điều kiện áp dụng chỉ có thể cài đặt khi đang tạo combo, không được chỉnh sửa sau khi đã lưu.',
	BUTTON_ADD_CONDITION: 'Thêm điều kiện',

	//VALUE
	TITLE_DISCOUNT_INFOS: 'Giá trị chương trình',
	SUBTITLE_COMBO_VALUE:
		'Bạn không thể thay đổi giá trị chương trình sau khi khởi tạo.',
	OPTION_DISCOUNT_BY_FIXED_AMOUNT: 'Giảm giá theo đơn vị tiền',
	HINT_DISCOUNT_BY_FIXED_AMOUNT:
		'Cổng Thanh toán điện tử không thể thực hiện giao dịch dưới 10.000VNĐ. Vui lòng đặt giá trị cao hơn',
	PLACEHOLDER_DISCOUNT_VALUE: 'Số tiền được giảm',
	PLACEHOLDER_INPUT_DISCOUNT_AMOUNT_VALUE: 'Số tiền được giảm',
	OPTION_DISCOUNT_BY_PERCENT: 'Giảm giá theo phần trăm',
	PLACEHOLDER_INPUT_DISCOUNT_PERCENT_VALUE: 'Giảm theo %',
	// MESSAGE
	MSG_CREATING: 'Đang tạo combo...',
	MSG_CREATE_SUCCESS: 'Tạo combo thành công',

}
const POPUP_ADD_CONDITION = {
	TITLE: 'Thêm điều kiện',
	TEXT_NOTE:
		'Khi giỏ hàng của học viên đạt đủ điều kiện, Combo giảm giá này sẽ được áp dụng.',
	//
	OPTION_COURSE_QUANTITY: 'Số lượng khoá học cần để đạt điều kiện',
	PLACEHOLDER_INPUT_COURSE_QUANTITY: 'Nhập số lượng khoá học trong đơn hàng',
	//
	OPTION_BILL_VALUE: 'Số tiền tối thiểu để đạt điều kiện',
	LABEL_CURRENCY_UNIT: 'VND',
	HINT_INPUT_BILL_VALUE: 'Vui lòng nhập số tiền lớn hơn 10.000VNĐ',
	//
	OPTION_SELECTED_COURSES:
		'Áp dụng combo khi mua các khóa sau trong 1 thời điểm',
	LABEL_COURSE: 'Khoá học',
	PLACEHOLDER_SELECT_COURSE: 'Chọn khóa học',
	POPUP_CONFIRM_REMOVE_COURSE: 'Bỏ khoá học này khỏi điều kiện?',
	MSG_REMOVING_COURSE: 'Đang loại bỏ khoá học',
	MSG_REMOVING_SUCCESS: 'Loại bỏ khoá học thành công',
	//
	BUTTON_SAVE_CONDITION: 'Lưu điều kiện',
	// MESSAGE
	MSG_CHECK_INFO_AGAIN: 'Vui lòng kiểm tra lại thông tin!',
	MSG_COURSES_IS_REQUIRED: 'Vui lòng chọn khoá học !',
	MSG_ADDING_COURSE: 'Đang thêm khoá học...',
	MSG_ADD_COURSE_SUCCESS: 'Thêm khoá học thành công!',
	MSG_ADD_COURSE_FAIL:
		'Thêm khoá học thất bại, không thể thêm khoá học đã tồn tại!',
	MSG_CONDITION_IS_REQUIRED: 'Vui lòng chọn điều kiện áp dụng!'
}
//MANAGE BILL
const BILL_LIST = {
	TITLE: 'Quản lý đơn hàng',
	LABEL_FILTER_BY_EMAIL_STUDENT: 'Email học viên',
	PLACEHOLDER_INPUT_EMAIL_STUDENT: 'Tìm theo email học viên',
	LABEL_AVAILABLE_AND_EXPIRED_DATE: 'Ngày bắt đầu - Ngày kết thúc',
	PLACEHOLDER_INPUT_SEARCH_TIME: 'Tìm theo khoảng thời gian',
	LABEL_STATUS_BILL: 'Tình trạng đơn',
	TEXT_STATUS_ALL: 'Tất cả',
	TEXT_STATUS_WAITTING: 'Đang chờ',
	TEXT_STATUS_FAIL: 'Thất bại',
	TEXT_STATUS_COMPLATED: 'Hoàn tất',
	TEXT_STATUS_REFUSED: 'Đã từ chối',
	TEXT_STATUS_PAID: 'Đã trả',
	// TABLE
	TH_STUDENT: 'Học viên',
	TH_CONTENT: 'Nội dung',
	TH_TIME: 'Thời gian',
	TH_PAYMENT_METHOD: 'Thanh toán',
	TH_VALUE: 'Đơn giá',
	TH_STATUS: 'Trạng thái',
	TH_ACTION: 'Thao tác',
	TEXT_STUDENT_CONTACT_INFO: 'Thông tin liên hệ',
	TEXT_COURSE_UNIT: 'Khoá học',
	TEXT_EBOOK: 'Tài liệu',
	TEXT_OTHER_PRODUCT: 'Sản phẩm',
	//BILL TABLE DETAIL
	TH_BILL_VALUE_BEFORE_DISCOUNT: 'Giá trước khuyến mãi (đ)',
	TH_BILL_VALUE_AFTER_DISCOUNT: '	Giá sau khuyến mãi (đ)',
	TEXT_RECEIVER_ADDRESS: 'Địa chỉ',
	TEXT_RECEIVER_NAME: 'Tên người nhận',
	TEXT_RECEIVER_PHONE: 'Số điện thoại',
	TEXT_EMAIL: "Email",
	TEXT_PRICE_BEFORE_DISCOUNT: 'Tạm tính',
	TEXT_COMBO_IS_APPLIED: 'Áp dụng Combo',
	TEXT_COUPON_IS_APPLIED: 'Áp dụng Coupon',
	TEXT_FINAL_PRICE: 'Tổng tiền',
	//
	BUTTON_REFUND_ALL: 'Hoàn trả tất cả',
	BUTTON_REJECT_ALL: 'Từ chối tất cả',
	BUTTON_APPROVE_ALL: 'Duyệt tất cả',
	BUTTON_REFUND: 'Hoàn trả',
	BUTTON_REJECT: 'Từ chối',
	//
	POPUP_CONFIRM_REFUND_BILL_TITLE: 'Hoàn trả đơn hàng?',
	POPUP_CONFIRM_REFUND_BILL_MESSAGE:
		'Đơn hàng bị hoàn trả sẽ không thể chỉnh sửa trạng thái. Học viên đang sở hữu sản phẩm trên nội dung đơn cũng sẽ mất quyền truy cập. Đơn hàng sẽ không được ghi nhận trong doanh số trong kỳ vừa rồi.',
	//
	POPUP_CONFIRM_APPROVE_BILL_TITLE: 'Xác nhận hoá đơn đã thanh toán?',
	POPUP_CONFIRM_APPROVE_BILL_MESSAGE:
		'Sau khi xác nhận hoá đơn học viên sẽ có quyền học khoá học',

	POPUP_CONFIRM_REJECT_BILL_TITLE: 'Từ chối đơn hàng',
	POPUP_CONFIRM_REJECT_BILL_MESSAGE:
		'Đơn hàng bị từ chối sẽ không thể được Duyệt lại cũng như không được ghi nhận trong doanh số.',
	//
	POPUP_CONFIRM_REFUND_MANY_BILLS_TITLE: 'Hoàn trả tất cả đơn hàng?',
	POPUP_CONFIRM_REFUND_MANY_BILLS_MESSAGE:
		'Đơn hàng bị hoàn trả sẽ không thể chỉnh sửa trạng thái. Học viên đang sở hữu sản phẩm trên nội dung đơn cũng sẽ mất quyền truy cập. Đơn hàng sẽ không được ghi nhận trong doanh số trong kỳ vừa rồi.',
	//
	POPUP_CONFIRM_APPROVE_MANY_BILLS_TITLE:
		'Xác nhận tất cả hoá đơn đã thanh toán ?',
	POPUP_CONFIRM_APPROVE_MANY_BILLS_MESSAGE:
		'Sau khi xác nhận hoá đơn học viên sẽ có quyền học khoá học',
	//
	POPUP_CONFIRM_REJECT_MANY_BILLS_TITLE: 'Từ chối tất cả các đơn hàng',
	POPUP_CONFIRM_REJECT_MANY_BILLS_MESSAGE:
		'Đơn hàng bị từ chối sẽ không thể được Duyệt lại cũng như không được ghi nhận trong doanh số.',
	//MESSAGE
	MSG_UPDATING: 'Đang cập nhật dữ liệu...',
	MSG_UPDATE_SUCCESS: 'Cập nhật dữ liệu thành công !'
}
//DELIVERY BILL
const DELIVERY_BILL_LIST = {
	TITLE: 'Theo dõi đơn hàng',
	PRODUCT_CODE: 'Mã đơn hàng',
	TRACKING_NUMBER: 'Mã vận đơn',
	BILL_STATUS: 'Trạng thái đơn',
	GOODS_STATUS: 'Trạng thái hàng',
	TIME_UPDATE: 'Thời gian cập nhật',
	DELIVERY_STATUS: 'Trạng thái vận chuyển',
	LABEL_PRODUCT_CODE_STATUS: 'Tình trạng hàng',
	LABEL_DELIVERY_STATUS: 'Tình trạng vận chuyển',
	LABEL_BILL_STATUS_STATUS: 'Tình trạng đơn',
	TEXT_STATUS_ALL: 'Tất cả',
	TEXT_STATUS_WAITTING: 'Chờ xác nhận',
	TEXT_STATUS_DELIVERY: 'Đang giao',
	TEXT_STATUS_REFUND: 'Hoàn hàng',
	TEXT_STATUS_DONE: 'Giao hàng thành công',
	TEXT_STATUS_REJECT: 'Đã huỷ',
	TEXT_STATUS_CONFIRMED: 'Đã xác nhận',
	TITLE_UPDATE_DELIVERY_BILL: 'Chỉnh sửa thông tin đơn',
	BTN_CANCEL: 'Huỷ',
	BTN_UPDATE: 'Cập nhật',
	TEXT_CHECK_BOX: 'Tìm theo đơn hàng không có mã vận đơn'
}
// MANAGE TRANSACTION
const TRANSACTION_LIST = {
	TITLE: 'Doanh số',
	//STATISTIC
	TITLE_STATISTIC: 'Thống kê',
	LABEL_TIME_BY_MONTH: 'Kỳ tháng',
	LABEL_NUMBER_OF_BILLS: 'Đơn phát sinh trong tháng',
	LABEL_REVENUE: 'Doanh thu thực nhận',
	LABEL_NUMBER_OF_SUCCESS_BILLS: 'Đơn thành công',
	TITLE_TRANSACTION_LIST: 'Chi tiết',
	TH_STUDENT: 'Người mua',
	TH_VALUE: 'Giá tiền',
	TH_CREATED_DATE: 'Ngày tạo',
	TH_STATUS: 'Trạng thái',
	TEXT_STATUS_SUCCES: 'Thành công',
	TEXT_STATUS_WAITING: 'Đang chờ',
	TEXT_STATUS_FAIL: 'Thất bại',
	TEXT_CURRENT_UNIT: 'đ'
}
//MANAGE TEACHER TRANSACTION
const TEACHER_TRANSACTION_LIST = {
	TITLE: "Doanh số của giáo viên",
	TITLE_STATISTIC: 'Thống kê',
	LABEL_BILL_QUANTITY: "Đơn trong tháng",
	LABEL_REVENUE: 'Doanh thu',
	LABEL_COURSE_QUANTITY_SOLD_IN_SIX_MONTH: "Số lượng khoá học bán trong 6 tháng",
	LABEL_TOTAL_BILL: "Tổng đơn",
	LABEL_REVENUE_IN_SIX_MONTH: "Doanh thu trong 6 tháng",
	LABEL_TOTAL_REVENUE: "Tổng doanh thu",
	LABEL_CURRENCY_UNIT: "(VNĐ)",
	LABEL_STATISTICS_WITH_SUCCESSFUL_ORDERS: "Số liệu thống kê được áp dụng với các đơn hàng có trạng thái thành công",
	GRAPH_STATISTIC: {
		LABEL_REVENUE: "Doanh thu (nghìn)",
		LABEL_REGISTER_QUANTITY: "Số lượng đăng kí",
	}
}
//MANAGE CONTACT
const CONTACT_LIST = {
	TITLE: 'Contact',
	TAB_CONTACT_WITHIN_BILL: 'Đơn hàng',
	TAB_CONTACT_WITHIN_CONTACT_FORM: 'Liên hệ',
	//
	LABEL_FILTER_BY_TIME: 'Bộ lọc thời gian',
	PLACEHOLDER_INPUT_TIME: 'Lọc khoảng thời gian',
	OPTION_TIME_SCOPE_WITHIN_THE_MONTH: 'Trong vòng 1 tháng',
	OPTION_TIME_SCOPE_UNLIMITED: 'Tất cả',
	// ORDER
	LABEL_FILTER_BY_PRODUCT_NAME: 'Tên sản phẩm',
	PLACEHOLDER_INPUT_PRODUCT_NAME: 'Nhập tên sản phẩm',
	TH_EMAIL: 'Email',
	TH_PHONE: 'Điện thoại',
	MSG_FILTERING_BILL: "Đang lọc đơn...",
	// CONTACT
	LABEL_SEARCH_BY_INFOS: 'Tìm kiếm liên hệ',
	PLACEHOLDER_INPUT_INFOS: 'Nhập thông tin theo bảng dưới',
	TH_DATE: 'Ngày',
	TH_STUDENT_NAME: 'Tên học viên',
	TH_PRODUCT_NAME: "Tên sản phẩn quan tâm",
	MSG_FILTERING: 'Đang lọc liên hệ',
	//TH_EMAIL: 'Mail',
	//TH_PHONE: 'Điện thoại',
	//MSG_FILTERING: 'Đang lọc liên hệ'
}
//MANAGE AFFILIATE
const AFFILIATE_LIST = {
	TITLE: 'Quản lý Affiliate',
	BUTTON_ADD: 'Thêm KOL',
	LABEL_FILTER_BY_NAME_EMAIL: 'Tên KOL',
	PLACEHOLDER_INPUT_NAME_EMAIL: 'Tìm kiếm theo tên và email KOL',
	TH_NAME: 'Tên KOL',
	TH_EMAIL: 'Email',
	TH_CREATED_DATE: 'Ngày tạo',
	TH_STATUS: 'Trạng thái',
	TH_ACTION: 'Thao tác',
	TOOLTIP_EDIT_AFFILIATE: 'Chỉnh sửa Affiliate',
	TEXT_STATUS_CONFIRMED: 'Đã xác nhận',
	TEXT_STATUS_UNCONFIRMED: 'Chưa xác nhận',
	POPUP_CONFIRM_DELETE_TITLE: 'Xoá KOL khỏi hệ thống',
	POPUP_CONFIRM_DELETE_MESSAGE: 'Dữ liệu sau khi xóa không thể khôi phục',
	//MESSAGE
	MSG_DELETE_SUCCESS: 'Xoá KOL thành công !',
	// EMPTY DATA
	TITLE_EMPTY_DATA: 'Chưa tồn tại KOL nào',
	SUBTITLE_EMPTY_DATA: 'Vui lòng tạo mới KOL để hiển thị trên danh sách này'
}
const FORM_CREATE_NEW_AFFILIATE = {
	TITLE: 'Tạo KOL mới',
	LABEL_FULL_NAME: 'Họ và tên',
	PLACEHOLDER_INPUT_FULL_NAME: 'Nhập họ và tên của KOL',
	LABEL_PHONE: 'Số điện thoại',
	PLACEHOLDER_INPUT_PHONE: 'Nhập số điện thoại KOL',
	LABEL_EMAIL: 'Email',
	PLACEHOLDER_INPUT_EMAIL: 'Nhập email của KOL',
	LABEL_PASSWORD: 'Mật khẩu',
	PLACEHOLDER_INPUT_PASSWORD: 'Nhập mật khẩu',
	LABEL_CONFIRM_PASSWORD: 'Nhập lại mật khẩu',
	PLACEHOLDER_INPUT_CONFIRM_PASSWORD: 'Nhập lại mật khẩu',
	LABEL_DESCRIPTION: "Mô tả KOL",
	PLACEHOLDER_DESCRIPTION: "Nhập mô tả",
	TITLE_AVATA: "Ảnh đại diện",
	MSG_CREATE_SUCCESS: 'Tạo KOL thành công !',
	MSG_CHECK_INFO_AGAIN: 'Vui lòng xem lại trường thông tin !'
}
const AFFILIATE_DETAIL = {
	TITLE: "Chi tiết KOL",
	LINK_RETURN_TO_AFILIATE_LIST: 'Danh sách Affiliate',
	TAB_CONFIGURATION: 'Cấu hình',
	TAB_REPORT: 'Báo cáo',
	// CONFIG_KOL
	TITLE_CONFIGURATION: 'Cấu hình',
	SUBTITLE_CONFIGURATION: 'Cài đặt cấu hình cho KOL',
	LABEL_FULL_NAME: 'Họ và tên',
	PLACEHOLDER_INPUT_FULL_NAME: 'Nhập họ và tên của KOL',
	LABEL_PHONE: 'Số điện thoại',
	PLACEHOLDER_INPUT_PHONE: 'Nhập số điện thoại của KOL',
	LABEL_EMAIL: 'Email',
	PLACEHOLDER_INPUT_EMAIL: 'VD: hanoiltv@gmail.com',
	TEXT_LIST: "Danh sách",
	TEXT_INTRODUCED: "Được giới thiệu",
	LABEL_PASSWORD: "Mật khẩu",
	PLACEHOLDER_INPUT_PASSWORD: "Nhập mật khẩu",
	LABEL_INPUT_PASSWORD_AGAIN: "Nhập lại mật khẩu",
	PLACEHOLDER_INPUT_PASSWORD_AGAIN: "Nhập lại mật khẩu",
	LABEL_DESCRIPTION_KOL: "Mô tả KOL",
	PLACEHOLDER_INPUT_DESCRIPTION: "Nhập mô tả",
	TITLE_AVATA: "Ảnh đại diện",

	//TAB
	TAB_ONLINE_COURSE: 'Khoá học Online',
	TAB_OFFLINE_COURSE: 'Khoá học Offline',
	TAB_EBOOK: 'Sách điện tử',
	TAB_EVENT: 'Sự kiện',
	//
	TH_NAME: 'Tên chương trình',
	TH_LINK: 'Link',
	TH_AVAILABLE_AT: 'Sử dụng từ',
	TH_EXPIRED_AT: 'Hạn sử dụng',
	TH_COMMISSION: 'Hoa hồng',
	TH_ACTION: 'Thao tác',

	//LIST_PRODUCT_AFFILIATE
	BUTTON_ADD: "Thêm chương trình",
	TEXT_CURRENT_UNIT: "đ",

	MSG_COPY_SUCCESS: 'Sao chép thành công',
	MSG_COPY_FAIL: 'Sao chép không thành công. Vui lòng thử lại',
	MSG_UPDATE_SUCCESS: 'Cập nhật thành công !',
	MSG_CHECK_PASSWORD_AGAIN: 'Mật khẩu không trùng khớp !',
	//MSG_CHECK_INFO_AGAIN: 'Vui lòng kiểm tra lại thông tin !',
	POPUP_CONFIRM_DELETION_TITLE: 'Xoá Chương trình',
	POPUP_CONFIRM_DELETION_MESSAGE: 'Dữ liệu sau khi xoá không thể khôi phục',
	MSG_DELETE_AFILIATE_SUCCES: 'Xoá afiliate thành công',
	//REPORT
	TITLE_REPORT: 'Báo cáo',
	SUBTITLE_REPORT: 'Thống kê hiệu quả giới thiệu chương trình từ KOL',
	LABEL_TIME_BY_MONTH: 'Kỳ tháng',
	LABEL_NUMBER_OF_BILLS: 'Số lượng đơn trong tháng',
	LABEL_REVENUE: 'Doanh thu thực nhận',
	LABEL_COMMISSION: 'Hoa Hồng',
	//
	TH_BILL_STUDENT: 'Học viên',
	TH_BILL_CONTENT: 'Nội dung',
	TH_BILL_VALUE: 'Đơn giá(đ)',
	TH_BILL_STATUS: 'Tình trạng',
	TH_BILL_CREATED_AT: 'Thời gian',
	TH_BILL_PAYMENT_METHOD: 'Hình thức thanh toán',
	TEXT_NO_MAIL: 'Không có email'
}
//POPUP ADD PROGRAM FOR KOL
const POPUP_ADD_PROGRAM = {
	TITLE_CREATE: 'Tạo chương trình',
	TEXT_NEW: "mới",
	LABEL_COURSE: 'Chọn khoá học',
	PLACEHOLDER_SELECT_COURSE: 'Tìm kiếm khoá học',
	LABEL_EBOOK: 'Chọn sách',
	PLACEHOLDER_SEARCH_EBOOK: 'Tìm kiếm sách',
	LABEL_EVENT: 'Chọn sự kiện',
	LABEL_DISCOUNT_TYPE: "Kiểu giảm giá",
	LABEL_DISCOUNT_VALUE: "Giá trị giảm giá",
	LABEL_COMMISSION_TYPE: 'Kiểu hoa hồng',
	OPTION_COMMISSION_PERCENT: 'Phần trăm (%)',
	OPTION_COMMISSION_FIX_VALUE: 'Cố định (đ)',
	LABEL_COMMISSION_VALUE: 'Giá trị hoa hồng',

	LABEL_AVAILABLE_DATE: 'Ngày bắt đầu',
	LABEL_AVAILABLE_HOUR: 'Giờ bắt đầu',
	LABEL_EXPIRED_DATE: 'Ngày kết thúc',
	LABEL_EXPIRED_HOUR: 'Giờ kết thúc',
	MSG_CREATING_AFFILIATE: 'Đang tạo affiliate...',
	MSG_CREATE_AFFILIATE_SUCCESS: 'Tạo affiliate thành công !',
	MSG_CHECK_INFO_AGAIN: 'Vui lòng kiểm tra lại thông tin !',

	//BASE_TREE_SELECT_TOPIC
	TITLE_TREE_SELECT_TOPIC: "Không có chủ đề nào được chọn.",
	//BaseCourseOfflinePicker
	LABEL_FILTER_COURSE: "Chọn khoá học",
	PLACEHOLDER_FILTER_COURSE: "Nhập để tìm kiếm",
	//BaseEventPicker
	LABEL_SELECT_EVENT: "Chọn sự kiện",
	MSG_GETTING_EVENT: "Đang lấy sự kiện",
	//BaseBookPicker
}
export default {
	ACTIVATION_CODE_CAMPAIGN_LIST,
	FORM_CREATE_NEW_ACTIVATION_CODE_CAMPAIGN,
	CAMPAIGN_ACTIVATION_CODE_DETAIL,
	COUPON_CAMPAIGN_LIST,
	FORM_CREATE_NEW_COUPON_CAMPAIGN,
	COUPON_CAMPAIGN_DETAIL,
	COMBO_CAMPAIGN_LIST,
	COMBO_CAMPAIGN_DETAIL,
	FORM_CREATE_NEW_COMBO_CAMPAIGN,
	BILL_LIST,
	TRANSACTION_LIST,
	TEACHER_TRANSACTION_LIST,
	POPUP_ADD_CONDITION,
	CONTACT_LIST,
	AFFILIATE_LIST,
	FORM_CREATE_NEW_AFFILIATE,
	AFFILIATE_DETAIL,
	POPUP_ADD_PROGRAM,
	BUTTON_CANCEL_TEXT: 'Huỷ',
	BUTTON_CANCEL: 'Huỷ bỏ',
	BUTTON_CONFIRM: 'Xác nhận',
	BUTTON_EDIT: 'Chỉnh sửa',
	BUTTON_SAVE: 'Lưu',
	BUTTON_ADD: 'Thêm',
	BUTTON_SEARCH: "Tìm kiếm",
	MSG_UPDATING_INFO: 'Cập nhật thông tin...',
	MSG_UPDATE_SUCCESS: 'Cập nhật thành công',
	MSG_SYSTEM_LOADING: 'Hệ thống đang tải dữ liệu, vui lòng thử lại sau!',
	MSG_DATA_SYSTEM_LOADING: 'Đang tải dữ liệu...',
	MSG_UPDATING_DATA: 'Đang cập nhật dữ liệu....',
	MSG_UPDATE_DATA_SUCCESS: 'Cập nhật dữ liệu thành công!',
	MSG_GETTING_DATA: 'Đang lấy dữ liệu...',
	MSG_CHECK_INFO_AGAIN: 'Vui lòng kiểm tra lại thông tin !',
	MSG_CHECH_KEYWORD_QUANTITY_AGAIN: 'Vui lòng chọn từ 2 khoá trở lên!',
	MSG_CHECK_PICK_COURSE_AGAIN: 'Vui lòng chọn khoá học !',
	DELIVERY_BILL_LIST
}
