import {RepositoryFactory} from '@/api/factory/repositoryFactory'
const orderManagerRepository = RepositoryFactory.get('orderManager')
import Base64 from '@/helpers/encodeBase64.js'

const state = {
  orderManagers: [],
  orderManager: {},
  count: 0,
}

const actions = {
  async createOrderManager({dispatch}, data) {
    const res = await orderManagerRepository.create({...data, password: Base64.encode(data.password)})
    dispatch('fetchOrderManagers')
    return res.data
  },
  async fetchOrderManagers({commit}, params = {}) {
    const res = await orderManagerRepository.fetch({
      ...params,
    })
    commit('setOrderManagers', res.data || [])
    commit('setCount', res.data?.length || 0)

    return res.data
  },
  async fetchOrderManager({commit}, id) {
    const res = await orderManagerRepository.fetchOne(id)
    commit('setOrderManager', res.data || [])
    return res.data
  },
  async updateOrderManager({dispatch}, {id, params}) {
    await orderManagerRepository.update(id, params)
    dispatch('fetchOrderManagers')
  },
  async changePassword({dispatch}, params= {}) {
    console.log('params',params);
    await orderManagerRepository.changePassword({...params, newPassword: Base64.encode(params.newPassword), oldPassword: Base64.encode(params.oldPassword)})
    dispatch('fetchOrderManagers')
  },
  
  async removeOrderManager({commit, rootState}, user) {
    const {orderManagers} = rootState.orderManager
    await orderManagerRepository.remove(user.id)
    const result = orderManagers.filter(item => item.id !== user.id)
    return commit('setOrderManagers', result)
  },
}

const mutations = {
  setOrderManager(state, orderManager) {
    return (state.orderManager = orderManager)
  },
  setOrderManagers(state, orderManagers) {
    return (state.orderManagers = orderManagers)
  },
  setCount(state, count) {
    return (state.count = count)
  },
}

const getters = {
  orderManagers: (state) => {
    return Object.values(state.orderManagers)
  },
  orderManager: (state) => {
    return state.orderManager
  },
  countOrderManager: (state) => {
    return state.count
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
