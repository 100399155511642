<template>
  <div>
    <v-navigation-drawer
      color="primary"
      dark
      permanent
      fixed
      touchless
      app
      width="328"
      mini-variant-width="68"
      :mini-variant.sync="isMinimized"
      :mobile-breakpoint="600"
    >
      <v-list class="pt-0">
        <v-list-item @click="onRedirect">
          <v-list-item-icon :class="{'mr-4': !isMinimized}">
            <!-- <v-icon>mdi-view-dashboard</v-icon> -->
            <img src="/admin-static/navigation-bar-icon/view-dashboard.svg" />
          </v-list-item-icon>
          <v-list-item-title class="text-uppercase font-weight-bold">
            {{ siteName }}
          </v-list-item-title>
          <v-list-item-action @click.stop="isMinimized = !isMinimized">
            <v-icon>mdi-chevron-double-left</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-group
          v-for="(item, i) in filteredItemsByRole"
          :key="i"
          :active-class="`white--text ${isMinimized ? 'is-minimized' : ''}`"
          no-action
          :to="item.path"
          :ripple="false"
          @click.stop="setMenuSectionIndex(i)"
          :value="menuSectionIndex === i"
        >
          <template v-slot:activator>
            <v-list-item-icon :class="{'mr-4': !isMinimized}">
              <img :src="item.icon" v-if="item.icon.startsWith('/')" />
              <v-icon v-else class="account-layout__icon">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-uppercase font-weight-medium">
                {{ $vuetify.lang.t(item.name) }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <transition-group :key="`key-${i}`" name="fade" mode="in-out" appear>
            <template v-for="(child, j) in item.children">
              <v-divider
                :key="`divider-key-${i}-${j}`"
                class="ml-13 mr-4 animate__animated animate__slideInLeft"
                v-if="child.hasTopDivider"
              />
              <v-list-item
                :key="`item-key-${i}-${j}`"
                active-class="primary--text white"
                :to="child.path"
                class="pl-14 animate__animated animate__slideInLeft"
                :ripple="false"
              >
                <v-list-item-content>
                  <v-list-item-title class="user-select-none">{{ $vuetify.lang.t(child.name) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </transition-group>
        </v-list-group>
        <v-list-item @click="onSignOut">
          <v-list-item-icon :class="{'mr-4': !isMinimized}">
            <v-icon>mdi-logout-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-uppercase font-weight-medium">
              {{ $vuetify.lang.t('$vuetify.ROUTE.NAVIGATION_ROUTES.SIGN_OUT') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="isMinimized" @click="isMinimized = !isMinimized">
          <v-list-item-icon>
            <v-icon>mdi-chevron-double-right</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view></router-view>
    </v-main>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import navigationRoutes from '@/router/navigationRoutes'
import helpers from '@/helpers/index.js'
export default {
  data() {
    return {
      isMinimized: false,
    }
  },
  computed: {
    ...mapGetters({
      siteName: 'vendor/getSiteName',
      authUser: 'auth/getAuthUser',
      menuSectionIndex: 'adminSettings/getMenuSectionIndex',
      vendor: 'vendor/getVendor',
    }),
    filteredItemsByRole() {
      const rolePermission = this.$utils.getRolePermission()
      if (typeof rolePermission.allScreens === 'undefined') return []
      let navRoutes = navigationRoutes

      if (this.vendor.vendorId !== '23b0bfb0-c00b-11e9-a060-17d3861b7a5b') {
        navRoutes = navigationRoutes.filter(
          (nav) => nav.name !== '$vuetify.ROUTE.NAVIGATION_ROUTES.PHYSICAL_PRODUCT.TITLE'
        )
      }
      if (rolePermission.allScreens) {
        const excludedScreens = rolePermission.excludedScreens
        if (Array.isArray(excludedScreens) && excludedScreens.length) {
          return navRoutes.map((nav) => {
            let children = []
            if (nav.type === 'group') {
              if (nav.name === '$vuetify.ROUTE.NAVIGATION_ROUTES.USER_MANAGE.TITLE') {
                children = nav.children.filter((item) => excludedScreens.indexOf(item.name) < 0)
              }
              children = nav.children
                .filter((item) => excludedScreens.indexOf(item.name) < 0)
                .filter((item) => {
                  if (
                    nav.name === '$vuetify.ROUTE.NAVIGATION_ROUTES.USER_MANAGE.TITLE' &&
                    helpers.VENDOR_SHOW_DELIVERY_BILL.indexOf(this.authUser.vendorId) < 0
                  ) {
                    return item.name !== '$vuetify.ROUTE.NAVIGATION_ROUTES.USER_MANAGE.LINK_TO_ORDER_MANAGER_LIST'
                  }
                  if (
                    nav.name === '$vuetify.ROUTE.NAVIGATION_ROUTES.FINANCE_MANAGE.TITLE' &&
                    helpers.VENDOR_SHOW_DELIVERY_BILL.indexOf(this.authUser.vendorId) < 0
                  ) {
                    return item.name !== '$vuetify.ROUTE.NAVIGATION_ROUTES.FINANCE_MANAGE.LINK_TO_DELIVERY_BILL_MANAGE_LIST'
                  }
                  return item
                })
              return {...nav, children}
            }

            return nav
          })
        }
        return navRoutes
      }
      return navRoutes
        .filter((nav) => {
          if (nav.type === 'item') {
            const existed =
              rolePermission && rolePermission.screens && rolePermission.screens.find((item) => item === nav.name)
            return !!existed
          }
          return nav
        })
        .filter((item) => {
          if (item.type === 'group' && item.name === '$vuetify.ROUTE.NAVIGATION_ROUTES.FINANCE_MANAGE.TITLE') {
            return helpers.VENDOR_SHOW_TEACHER_SALE.indexOf(this.authUser.vendorId) > -1
          } else {
            return item.type === 'group' && rolePermission.groups.find((group) => group === item.name)
          }
        })
        .map((item) => {
          if (item.type === 'group') {
            const children = item.children.filter((child) => {
              const existed =
                rolePermission && rolePermission.screens && rolePermission.screens.find((item) => item === child.name)
              return !!existed
            })
            if (!children.length) return null
            return {
              ...item,
              children,
            }
          }
          return item
        })
        .filter(Boolean)
    },
  },
  methods: {
    ...mapActions({
      signOut: 'auth/signOut',
      setMenuSectionIndex: 'adminSettings/setMenuSectionIndex',
    }),
    onRedirect() {
      if (!this.$utils.isKol()) this.$router.push('/dashboard')
    },
    async onSignOut() {
      this.$confirm.show({
        title: this.$vuetify.lang.t('$vuetify.AUTHENTICATION.SIGN_OUT.POPUP_CONFIRM_SIGN_OUT_TITLE'),
        text: this.$vuetify.lang.t('$vuetify.AUTHENTICATION.SIGN_OUT.POPUP_CONFIRM_SIGN_OUT_MESSAGE'),
        okText: this.$vuetify.lang.t('$vuetify.AUTHENTICATION.SIGN_OUT.BUTTON_CONFIRM'),
        cancelText: this.$vuetify.lang.t('$vuetify.AUTHENTICATION.SIGN_OUT.BUTTON_CANCEL'),
        done: async () => {
          await this.signOut()
          this.$router.go('/auth/signIn')
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .theme--dark.v-list-item--active:before,
.theme--light.v-list-item--active:hover:before,
.theme--light.v-list-item:focus:before {
  opacity: 0;
}
::v-deep .v-list-item--active.is-minimized {
  background-color: white;
  & .v-list-item__icon {
    color: #255cc2;
  }
}
.account-layout__icon {
  font-size: 20px !important;
}
</style>
